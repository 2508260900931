import { ProjectBasicFragment } from './../modules/projects/gql/generated/ProjectBasicFragment';

import { REGIONAL_APPS_HOSTNAME } from 'env';

export const useGetStudioOrClassicURL = () => {
  return {
    /**
      Goals of this function:

      - If `project.opensInClassic` is `true` (which should only happen for Enterprise customers), use classic

      - Otherwise use studio
    */
    getUrl(project: ProjectBasicFragment, environmentName?: string) {
      const appName = project.opensInClassic ? 'classic' : 'studio';
      // Get environmentId by removing dashes from the environmentName or defaultEnvironment, or fallback to an empty string
      const environmentId = (
        environmentName ??
        project.defaultEnvironment ??
        'master'
      ).replaceAll('-', '');

      // Use the environment name or id based on whether the project opens in Classic mode
      const environmentNameOrId = project.opensInClassic
        ? 'master'
        : environmentId;
      return regionalProjectUrl(project, appName, environmentNameOrId);
    },
  };
};

function regionalProjectUrl(
  { id, region: { id: regionId } }: ProjectBasicFragment,
  mode: 'studio' | 'classic',
  environmentNameOrId?: string | null
) {
  const { protocol, port } = location;
  const regionSubdomain = regionId.toLocaleLowerCase();
  let hostname = REGIONAL_APPS_HOSTNAME!.replace('{region}', regionSubdomain);
  if (mode === 'studio') {
    hostname = hostname.replace('app', 'studio');
  }
  return `${protocol}//${hostname}${port ? `:${port}` : ''}/${id}/${
    environmentNameOrId ?? 'master'
  }`;
}
